import * as React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { useEffect, useRef, useState } from "react"

import Link from "gatsby-link";


import * as styles from "../../scss/privacy.module.scss"


const Page = () => {
    return (
        <Layout>
            <SEO
                title="Teilnahmebedingungen Gewinnspiel DetoxMe!"
                description="Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
                keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                author="spectory"
            />
            <div className={styles.responsiveWrapper}>
                <div className={styles.hero}>
                    <div className={`clearfix container ${styles.container}`} style={{ margin: 0, padding: 0 }}>
                        <div className={styles.intro}>
                            <h1>
                                Teilnahmebedingungen Gewinnspiel DetoxMe!
                            </h1>
                            <ol>
                                <li>Veranstalter des Gewinnspiels ist Mag. Johannes Koch-Geiger EU. Das Gewinnspiel läuft vom 09.09.-19.09.2021. Mit der Teilnahme am Gewinnspiel akzeptieren die TeilnehmerInnen die nachfolgend genannten Teilnahmebedingungen. Für die Organisation, Abwicklung sowie Preisbereitstellung ist Mag. Johannes Koch-Geiger EU zuständig und verantwortlich. Dieses Gewinnspiel steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert.</li>
                                <li>Unter allen Kommentaren auf Facebook und Instagram unter dem jeweiligen Gewinnspiel-Posting im Zeitraum 09.09.-19.09.2021ermittelt Mag. Johannes Koch-Geiger EU nach Ablauf des Durchführungszeitraumes unter Ausschluss der Öffentlichkeit sowie ohne Beisein eines Notars per Zufallsprinzip den/die Gewinner/in. Kommentare, die gegen die Facebook-Richtlinien, österreichisches Recht, unsere Netiquette und/oder gegen Rechte Dritter verstoßen, werden nach Kenntnisnahme ohne Ankündigung entfernt. Der/die Teilnehmer/in ist damit von der Verlosung ausgeschlossen.</li>
                                <li>Der/die Gewinner/in wird per Direktnachricht verständigt und muss innerhalb von zwei Wochen seinen Gewinn antreten. Die Inanspruchnahme des Gewinns erfolgt durch schriftliche Kontaktaufnahme unter Angabe der vollständigen, für die Durchführung des Gewinnspiels erforderlichen Kontaktdaten. Auf Aufforderung ist eine Ausweiskopie zu übermitteln.</li>
                                <li>Meldet sich ein/e Gewinner/in nicht innerhalb dieser Frist (zwei Wochen), verfällt der Gewinnanspruch. Der Rechtsweg ist ausgeschlossen. Eine Barauszahlung der Gewinne ist nicht möglich. Gewinnansprüche sind nicht auf andere Personen übertragbar. Die GewinnerInnen sind nicht zum Umtausch, zum Verkauf oder zur Weitergabe des Gewinns berechtigt. Der Gewinn wird per E-Mail/Post an den/die Gewinner/in übermittelt.</li>
                                <li>Teilnahmeberechtigt sind Personen mit Wohnsitz in einem Mitgliedstaat der Europäischen Union oder der Schweiz, die bei der Teilnahme mindestens 18 Jahre alt sind. Bei einem Verstoß gegen diese Teilnahmebedingungen behält sich Mag. Johannes Koch-Geiger EU das Recht vor, Personen vom Gewinnspiel auszuschließen.</li>
                                <li>Das Gewinnspiel unterliegt dem österreichischen Recht. Die Mag. Johannes Koch-Geiger EU behält sich vor, das Gewinnspiel aus wichtigen Gründen zu jedem Zeitpunkt abzubrechen. Ein Abbruch aus wichtigem Grund kann insbesondere dann erfolgen, wenn eine ordnungsgemäße Durchführung aus technischen oder rechtlichen Gründen nicht mehr gewährleistet werden kann. Bei einem Abbruch bestehen keinerlei Ansprüche gegenüber Mag. Johannes Koch-Geiger EU.</li>
                                <li>Soweit im Einzelfall nur die männliche oder weibliche Form bei Ausdrücken gewählt wurde, wird ausdrücklich darauf hingewiesen, dass diese geschlechtsneutral zu verstehen sind und sich sowohl auf männliche als auch auf weibliche Teilnehmer beziehen.</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div style={{ height: 40, backgroundColor: "#E4F8EF", width: "100%", position: "absolute", bottom: 0, left: 0, display: "block" }}></div>
            </div>
        </Layout>
    )
}

export default Page